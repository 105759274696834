import { Table, Row, Col, Space, Button, Collapse, Pagination, message, Spin, DatePicker, Image, Badge, Form, Popconfirm, Modal } from 'antd'
import { PlusOutlined, FileExcelOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT, LOADING, PAGESIZE, PAGINATIONSIZE, SEARCHOPTIONVIEWWIDTH, UNIDATETIMEFORMAT, FALLBACK, UNIDATEFORMAT, MEDIAMAXWIDTH } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserProjectId, getUserProjectName, SYSPARAM, SEARCH_RECORDTYPEID_KEY, SEARCH_RECORDTYPE_KEY, SEARCH_DISTRICTID_KEY, SEARCH_DISTRICT_KEY, SEARCH_ZONEID_KEY, SEARCH_ZONE_KEY, SEARCH_ROADIDID_KEY, SEARCH_ROADID_KEY, SEARCH_DATEFROM_KEY, SEARCH_DATETO_KEY, SEARCH_SRT_KEY } from "../common/usersession"
import { useNavigate } from "react-router"
import { reportError } from '../common/utility'
import dayjs from 'dayjs'
import CommonSearchFormItem from '../common/commonsearchformitem'
import SRTSelect from '../common/srtselect'
import { useMediaQuery } from 'react-responsive'
import RecordTypeSelect from '../common/recordtypesselect'

const { Panel } = Collapse
const { RangePicker } = DatePicker
const { confirm } = Modal


const RoadAuditTable = () => {
    const FileDownload = require('js-file-download')
    const navigate = useNavigate()
    const [commonSearchForm] = Form.useForm()

    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : SEARCHOPTIONVIEWWIDTH)
    
    //-------------------------------------
    // Always get previously stored values.
    const [districtId, setDistrictId] = useState(window.sessionStorage.getItem(SEARCH_DISTRICTID_KEY) != null ? parseInt(window.sessionStorage.getItem(SEARCH_DISTRICTID_KEY)) : 0)
    const [district, setDistrict] = useState(window.sessionStorage.getItem(SEARCH_DISTRICT_KEY))
    const [zoneId, setZoneId] = useState(window.sessionStorage.getItem(SEARCH_ZONEID_KEY) != null ? parseInt(window.sessionStorage.getItem(SEARCH_ZONEID_KEY)) : 0)
    const [zone, setZone] = useState(window.sessionStorage.getItem(SEARCH_ZONE_KEY))
    const [roadId, setRoadId] = useState(window.sessionStorage.getItem(SEARCH_ROADIDID_KEY) != null ? parseInt(window.sessionStorage.getItem(SEARCH_ROADIDID_KEY)) : 0)
    const [road, setRoad] = useState(window.sessionStorage.getItem(SEARCH_ROADID_KEY))

    const [dateRange, setDateRange] = useState([
        window.sessionStorage.getItem(SEARCH_DATEFROM_KEY) != '' && window.sessionStorage.getItem(SEARCH_DATEFROM_KEY) != null ? dayjs(window.sessionStorage.getItem(SEARCH_DATEFROM_KEY)) : dayjs().add(-1, 'week').add(1, 'day'),
        window.sessionStorage.getItem(SEARCH_DATETO_KEY) != '' && window.sessionStorage.getItem(SEARCH_DATETO_KEY) != null ? dayjs(window.sessionStorage.getItem(SEARCH_DATETO_KEY)) : dayjs()
    ])
        
    const [srt, setSrt] = useState(window.sessionStorage.getItem(SEARCH_SRT_KEY) != '' && window.sessionStorage.getItem(SEARCH_SRT_KEY) != null ? window.sessionStorage.getItem(SEARCH_SRT_KEY) : '')
    const [recordTypeId, setRecordTypeId] = useState(window.sessionStorage.getItem(SEARCH_RECORDTYPEID_KEY) != null ? parseInt(window.sessionStorage.getItem(SEARCH_RECORDTYPEID_KEY)) : 0)
    const [recordType, setRecordType] = useState(window.sessionStorage.getItem(SEARCH_RECORDTYPE_KEY) != '' ? window.sessionStorage.getItem(SEARCH_RECORDTYPE_KEY) : '')

    //-------------------------------------
    const [auditDataSource, setAuditDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [previewPhotos, setPreviewPhotos] = useState([])
    
    
    const searchRoadAudit = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}roadauditpaginated/`, {
            params: { 
                project: getUserProjectId(),
                district: districtId,
                zone: zoneId,
                road: roadId,
                from_date: dateRange[0] == null ? null : dateRange[0].format(UNIDATEFORMAT),
                to_date: dateRange[1] == null ? null : dateRange[1].format(UNIDATEFORMAT),
                srt: srt,
                record_type: recordTypeId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search road audit.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( audit => {
            data.push({
                id: audit.id,
                auditedOn: dayjs(audit.audited_on).format(DATETIMEFORMAT),
                auditedOnUnformatted: dayjs(audit.audited_on).format(UNIDATETIMEFORMAT),
                district: audit.district_data.name,
                zone: audit.zone_data.name,
                list: audit.road_data.list,
                roadIdId: audit.road_data.id,
                roadId: `${audit.road_data.list} ${audit.road_data.road_id}`,
                roadName: audit.road_data.road_name,
                roadNameAndId: `${audit.road_data.road_name} (${audit.road_data.list} ${audit.road_data.road_id})`,
                gpsLocation: audit.gps_location != null && audit.gps_location != '' ? audit.gps_location : '-',
                chainageFrom: audit.chainage_from != null && audit.chainage_from != '' ? audit.chainage_from : '-',
                chainageTo: audit.chainage_to != null && audit.chainage_to != '' ? audit.chainage_to : '-',
                findings: audit.findings,
                img: `${audit?.photo_data[0]?.img}?now=${dayjs().valueOf()}`,
                photoCount: `${audit.photo_data.length}`,
                photos: audit?.photo_data,
                recordTypeId: audit.record_type,
                recordType: audit.record_type_data.name,
                findings: audit.record_type != SYSPARAM("Conclusion") ? audit.findings : audit.conclusion,
                srt: audit.road_data.srt,
                roadLength: audit.road_data.road_length,
                affectedLength: audit.road_data.affected_length_m
            })
        })
        
        setAuditDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const downloadSearchResult = () => {
        setIsLoading(true)
                
        // Build search criteria string for display in pdf
        let searchCriteria = `Project: ${getUserProjectName()}`
        searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(districtId != 0) searchCriteria = `${searchCriteria}\nDistrict: ${district}`
        if(zoneId != 0) searchCriteria = `${searchCriteria}\nZone: ${zone}`
        if(roadId != 0) searchCriteria = `${searchCriteria}\nRoad: ${road.toString()}`
        if(srt != '') searchCriteria = `${searchCriteria}\nSRT: ${srt}`
        if(recordTypeId != 0) searchCriteria = `${searchCriteria}\nRecord Type: ${recordType}`
                                        
        axios.get(`${APIURL}roadaudit/download/`, {
            params: { 
                project: getUserProjectId(),
                district: districtId,
                zone: zoneId,
                road: roadId,
                from_date: dateRange[0] == null ? null : dateRange[0].format(UNIDATEFORMAT),
                to_date: dateRange[1] == null ? null : dateRange[1].format(UNIDATEFORMAT),
                srt: srt,
                record_type: recordTypeId,
                search_criteria: searchCriteria
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                let ext = "xlsx"
                FileDownload(response.data, `Road Audit ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const downloadExcelPrompt = (e, record) => {
        e.stopPropagation()

        confirm({
            icon: <QuestionCircleOutlined />,
            title: "Align to PDF?",
            content: <Space><p>Generate excel aligned to PDF format?</p></Space>,
            okText: "Yes",
            cancelText: "No",
            onOk() { 
                confirm({
                    icon: <QuestionCircleOutlined />,
                    title: "Show roadmap and observation?",
                    content: <Space><p>Include roadmap and observation in generated excel?</p></Space>,
                    okText: "Yes",
                    cancelText: "No",
                    onOk() { downloadExcel(record, true, true) },
                    onCancel() { downloadExcel(record, true, false) },
                    centered: true
                })
            },
            onCancel() { 
                confirm({
                    icon: <QuestionCircleOutlined />,
                    title: "Show roadmap and observation?",
                    content: <Space><p>Include roadmap and observation in generated excel?</p></Space>,
                    okText: "Yes",
                    cancelText: "No",
                    onOk() { downloadExcel(record, false, true) },
                    onCancel() { downloadExcel(record, false, false) },
                    centered: true
                })
            
            },
            centered: true
        })
    }

    const downloadExcel = (record, printableVersion, showMapObservation) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}roadaudit/excelreport/`, {
            params: { 
                project: getUserProjectId(),
                audit_id: record.id,
                printable_version: printableVersion,
                show_map_observation: showMapObservation
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 12),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                FileDownload(response.data, `${getUserProjectName()}_${record.roadName}_${record.roadId}_${now}.xlsx`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download road audit excel.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const downloadCombinedSingleRoadExcelPrompt = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            title: "Align to PDF?",
            content: <Space><p>Generate excel aligned to PDF format?</p></Space>,
            okText: "Yes",
            cancelText: "No",
            onOk() { 
                confirm({
                    icon: <QuestionCircleOutlined />,
                    title: "Show map and observation?",
                    content: <Space><p>Include roadmap and observation in generated excel?</p></Space>,
                    okText: "Yes",
                    cancelText: "No",
                    onOk() { downloadCombineSingleRoadExcel(true, true) },
                    onCancel() { downloadCombineSingleRoadExcel(true, false) },
                    centered: true
                })
            },
            onCancel() { 
                confirm({
                    icon: <QuestionCircleOutlined />,
                    title: "Show map and observation?",
                    content: <Space><p>Include roadmap and observation in generated excel?</p></Space>,
                    okText: "Yes",
                    cancelText: "No",
                    onOk() { downloadCombineSingleRoadExcel(false, true) },
                    onCancel() { downloadCombineSingleRoadExcel(false, false) },
                    centered: true
                })
            
            },
            centered: true
        })
    }

    const downloadCombineSingleRoadExcel = (printableVersion, showMapObservation) => {
        setIsLoading(true)
                                
        axios.get(`${APIURL}roadaudit/excelreport/`, {
            params: { 
                project: getUserProjectId(),
                district: districtId,
                zone: zoneId,
                road_id: roadId,
                combine: true,
                from_date: dateRange[0] == null ? null : dateRange[0].format(UNIDATEFORMAT),
                to_date: dateRange[1] == null ? null : dateRange[1].format(UNIDATEFORMAT),
                srt: srt,
                printable_version: printableVersion,
                show_map_observation: showMapObservation
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 36),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                FileDownload(response.data, `${getUserProjectName()}_${road}_${now}.xlsx`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download road audit excel.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const showPhotos = (e, record) => {
        e.stopPropagation()

        const photos = []
        record.photos.forEach( photo => {
            photos.push(<Image fallback={FALLBACK} src={`${photo.img}?now=${new Date()}`}/>)
        })
        
        setPreviewPhotos(photos)
        setVisible(true)
    }
    
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchRoadAudit(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSearch = () => {
        setCurrentPage(1)
        searchRoadAudit(1)
    }

    const onReset = () => {
        window.sessionStorage.setItem(SEARCH_DISTRICTID_KEY, 0)
        window.sessionStorage.setItem(SEARCH_DISTRICT_KEY, '')
        window.sessionStorage.setItem(SEARCH_ZONEID_KEY, 0)
        window.sessionStorage.setItem(SEARCH_ZONE_KEY, '')
        window.sessionStorage.setItem(SEARCH_ROADIDID_KEY, 0)
        window.sessionStorage.setItem(SEARCH_ROADID_KEY, '')
        window.sessionStorage.setItem(SEARCH_DATEFROM_KEY, '')
        window.sessionStorage.setItem(SEARCH_DATETO_KEY, '')
        window.sessionStorage.setItem(SEARCH_SRT_KEY, '')

        window.location.reload()
    }

    const onCreateNew = () => {
        navigate("/roadauditnew")
    }

    const onRowClick = (record, rowIndex) => {
        let chainageTo = record.chainageTo
        if(chainageTo == '-') chainageTo = ''

        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/roadauditupdate", 
                    search: `?id=${record.id}&roadIdId=${record.roadIdId}&roadNameAndId=${record.roadNameAndId}&auditedOnUnformatted=${record.auditedOnUnformatted}&gpsLocation=${record.gpsLocation}&chainageFrom=${record.chainageFrom}&chainageTo=${chainageTo}&recordTypeId=${record.recordTypeId}&recordType=${record.recordType}`
                }) 
            }
        }
    }

    const onSearchDistrictChange = (e, value) => {
        if(e != undefined) {
            setDistrictId(e)
            setDistrict(value.children)
        }
        else {
            setDistrictId(0)
            setDistrict("")
        }
    }

    const onSearchZoneChange = (e, value) => {
        if(e != undefined) {
            setZoneId(e)
            setZone(value.children)
        }
        else {
            setZoneId(0)
            setZone("")
        }
    }

    const onSearchRoadChange = (e, value) => {
        if(e != undefined) {
            setRoadId(e)
            setRoad(value.children)
        }
        else {
            setRoadId(0)
            setRoad("")
        }
    }

    const onDateRangeChange = (dateRange) => {
        if(dateRange == null) {
            setDateRange([null, null])
            window.sessionStorage.setItem(SEARCH_DATEFROM_KEY, null)
            window.sessionStorage.setItem(SEARCH_DATETO_KEY, null)
        }
        else {
            setDateRange(dateRange)
            window.sessionStorage.setItem(SEARCH_DATEFROM_KEY, dateRange[0].format(UNIDATEFORMAT))
            window.sessionStorage.setItem(SEARCH_DATETO_KEY, dateRange[1].format(UNIDATEFORMAT))
        }
    }

    const onSrtChange = (e) => {
        if(e != 0) {
            setSrt(e)
            window.sessionStorage.setItem(SEARCH_SRT_KEY, e)
        }
        else {
            setSrt('')
            window.sessionStorage.setItem(SEARCH_SRT_KEY, '')
        }
    }

    const onRecordTypeChange = (e, value) => {
        if(e != undefined) {
            setRecordTypeId(e)
            setRecordType(value.children)
            window.sessionStorage.setItem(SEARCH_RECORDTYPEID_KEY, e)
            window.sessionStorage.setItem(SEARCH_RECORDTYPE_KEY, value.children)
        }
        else {
            setRecordTypeId(0)
            setRecordType('')
            window.sessionStorage.setItem(SEARCH_RECORDTYPEID_KEY, 0)
            window.sessionStorage.setItem(SEARCH_RECORDTYPE_KEY, '')
        }
    }

    useEffect(() => {
        searchRoadAudit(currentPage)
    }, [])

    const columns = [
        { title: 'Audited On', dataIndex: 'auditedOn', key: 'auditedOn', align: 'center', sorter: (a, b) => a.auditedOn.localeCompare(b.auditedOn) },
        { title: 'District', dataIndex: 'district', key: 'district', align: 'center', sorter: (a, b) => a.distict.localeCompare(b.distict) },
        { title: 'Zone', dataIndex: 'zone', key: 'zone', align: 'center', sorter: (a, b) => a.zone.localeCompare(b.zone) },
        { title: 'List', dataIndex: 'list', key: 'list', align: 'center', sorter: (a, b) => a.list.localeCompare(b.list) },
        { title: 'Road ID', dataIndex: 'roadId', key: 'roadId', align: 'center', sorter: (a, b) => a.roadId.localeCompare(b.roadId) },
        { title: 'Road Name', dataIndex: 'roadName', key: 'roadName', align: 'center', sorter: (a, b) => a.roadName.localeCompare(b.roadName) },
        { title: 'SRT', dataIndex: 'srt', key: 'srt', align: 'center', sorter: (a, b) => a.srt.localeCompare(b.srt) },
        { title: 'Road Length (m)', dataIndex: 'roadLength', key: 'roadLength', align: 'center', sorter: (a, b) => a.roadLength.localeCompare(b.roadLength) },
        { title: 'Affected Length (m)', dataIndex: 'affectedLength', key: 'affectedLength', align: 'center', sorter: (a, b) => a.affectedLength.localeCompare(b.affectedLength) },
        { title: 'Chainage (From)', dataIndex: 'chainageFrom', key: 'chainageFrom', align: 'center', sorter: (a, b) => a.chainageFrom.localeCompare(b.chainageFrom) },
        { title: 'Chainage (To)', dataIndex: 'chainageTo', key: 'chainageTo', align: 'center', sorter: (a, b) => a.chainageTo.localeCompare(b.chainageTo) },
        { title: 'No. of Photos', dataIndex: 'photoCount', key: 'photoCount', align: 'center', sorter: (a, b) => a.photoCount.localeCompare(b.photoCount) },
        { title: 'Record Type', dataIndex: 'recordType', key: 'recordType', align: 'left', sorter: (a, b) => a.recordType.localeCompare(b.recordType) },
        { title: 'Onsite Findings/Conclusion', dataIndex: 'findings', key: 'onsiteFindings', align: 'left', sorter: (a, b) => a.findings.localeCompare(b.findings) },
        { title: 'Photo', dataIndex: 'img', key: 'img', align: 'center',
            render: (img, record) => {
                if(img != null) 
                    return <>
                        <Badge count={record.photoCount}>
                            <Image width={50} fallback={FALLBACK} src={img} preview={false} onClick={(e) => showPhotos(e, record) }/>
                        </Badge>
                    </>
                else
                    return null
            }
        },
        { key: 'action', align: 'center', width: 100,
            render: (record) => {
                return <Button type="primary" icon={<FileExcelOutlined/>} onClick={e => downloadExcelPrompt(e, record)} />
            }
        }
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search road audit" key="1">
                        <CommonSearchFormItem onDistrictChange={onSearchDistrictChange} onZoneChange={onSearchZoneChange} onRoadChange={onSearchRoadChange} 
                            commonSearchForm={commonSearchForm} defaultDistrictId={districtId} defaultDistrict={district} defaultZoenId={zoneId} 
                            defaultZone={zone} defaultRoadIdId={roadId} defaultRoadId={road}/>

                        <Row justify="center">
                            <Col span={1} style={{ textAlign: 'end', marginRight: 10 }}>Date Range :</Col>
                            <Col span={3}>
                                <RangePicker onChange={onDateRangeChange} defaultValue={dateRange} format={DATEFORMAT} style={{width: optionWidth}} />
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>SRT :</Col>
                            <Col span={2}>
                                <SRTSelect onChange={onSrtChange} style={{width: optionWidth}} withBlank={true} allowClear={true} defaultValue={srt} />
                            </Col>
                            <Col span={2}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Record Type :</Col>
                            <Col span={3}>
                                <RecordTypeSelect onChange={onRecordTypeChange} style={{width: optionWidth}} withBlank={true} allowClear={true} defaultValue={recordType} />
                            </Col>
                        </Row>
            
                        <Row><Col><Space><div /></Space></Col></Row>
                        
                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult()} loading={isLoading} icon={<FileExcelOutlined />} />
                                {/* <Popconfirm
                                    title="Align to PDF?"
                                    description="Generate excel aligned to PDF format?"
                                    onConfirm={() => downloadCombineSingleRoadExcel(true)}
                                    onCancel={() => downloadCombineSingleRoadExcel(false)}
                                    okText="Yes"
                                    cancelText="No">
                                    <Button type="primary" htmlType="button" loading={isLoading} icon={<FileExcelOutlined />}>Combine Single Road Records</Button>
                                </Popconfirm> */}
                                <Button type="primary" htmlType="button" loading={isLoading} onClick={downloadCombinedSingleRoadExcelPrompt} icon={<FileExcelOutlined />}>Combine Single Road Records</Button>
                                <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={auditDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <div style={{ display: 'none'}}>
            <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}>{previewPhotos}</Image.PreviewGroup>
        </div>

        </Spin>
        </>
    )
}

export default RoadAuditTable